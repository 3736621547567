import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import Homepage from "./pages/Homepage";
import Portfolio from "./pages/Portfolio";
import PortfolioItem from "./pages/PortfolioItem";
import Contact from "./pages/Contact";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path={'/'} element={<Homepage />} />
          <Route path={'/portfolio'} element={<Portfolio />} />
          <Route path={'/portfolio/:id'} element={<PortfolioItem />} />
          <Route path={'/contact-me'} element={<Contact />} />
        </Routes>
      </Router>
    </div>
  )
}
export default App;