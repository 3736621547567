import GithubIcon from "../assets/images/github.png";
import LinkedinIcon from "../assets/images/linkedin.png";
import TwitterIcon from "../assets/images/twitter.png";

const Footer = ()=>{
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-head">
          Lets Connect
        </div>
        <div className="footer-text">
          Feel free to connect for collaborations, workings and projects
        </div>
        <div className="footer-email">
          <a href="mailto:yinkash1000@gmail.com">
            yinkash1000@gmail.com
          </a>
        </div>
        <div className="footer-social">
          <a href="https://www.github.com/yinkash100" target="_blank" rel="noreferrer" className="footer-social-item">
            <img src={GithubIcon} alt="Adeyinka Adebiyi on Github" />
          </a>
          <a href="https://www.linkedin.com/in/yinkash1000" target="_blank" rel="noreferrer"
             className="footer-social-item">
            <img src={LinkedinIcon} alt="Adeyinka Adebiyi on Linkedin" />
          </a>
          <a href="https://www.x.com/yinkash1000" target="_blank" rel="noreferrer" className="footer-social-item">
            <img src={TwitterIcon} alt="Adeyinka Adebiyi on Twitter" />
          </a>
        </div>
      </div>
    </div>
  )
}
export default Footer;