import {useEffect, useRef} from 'react';

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};


const FloatingItems = (Props)=>{
  const { item, containerRef } = Props;
  let intervalTimer = useRef(0);
  const requestRef = useRef();
  const canvas = useRef(null);
  // const contextRef = useRef(null);
  let dotRadius = Math.random() * item.bubbleAverageRadius + 5;
  let context = useRef(null);

  useEffect(()=>{
    context.current = canvas.current?.getContext('2d');
  }, [canvas])

  useEffect(()=>{

    let canvasWidth  = containerRef.current?.offsetWidth;
    let canvasHeight  = containerRef.current?.offsetHeight;

    if(context) {
      // Set the number of frames we want to run.
      const totalFrameCount = item.bubbleDuration*60;
      // const opacityDiffPerFrame = 1/totalFrameCount;

      // We have a currentFrame reference which essentially tracks our place in time.
      let currentFrame = 0;

      // Set and create our dot.
      const dotCount = item.bubbleCount;
      let initialDotArray = Array(dotCount).fill(0).map(a => {
        // the bubbles are generated randomly within 20% of the
        // center of the canvas
        const percent20OfCanvasWidth = getRandomInt(-20, 20) * (canvasWidth / 100) + canvasWidth / 2;
        const percent20OfCanvasHeight = getRandomInt(-20, 20) * (canvasHeight / 100) + canvasHeight / 2;
        const bubbleColor = hexToRgbA(item.bubbleColorAssorted ? getRandomColor() : item.bubbleColor, item.bubbleOpacity)
        return { x: percent20OfCanvasWidth, y: percent20OfCanvasHeight, radius: dotRadius, color: bubbleColor, alpha: 1  }
      });
      let dotsArray = [...initialDotArray];
      // generates a random path for the bubbles
      let initialPathArray = Array(dotCount).fill(0).map(a => ({ pathX: getRandomInt(-20, 20), pathY: getRandomInt(-20, 20) }));
      let pathArray = [...initialPathArray];

      dotsArray.forEach((dot, index) => {
        drawDot(context, dot)
      })

      const moveDot = () => {
        // Clear the canvas so we can draw on it again.
        context.current?.clearRect(0, 0, canvasWidth, canvasHeight)
        let tempDotsArray = [];

        dotsArray.forEach((dot, index) => {
          if (dot.alpha <= 0) return;
          // Draw the dot again (remember, we wiped it away with clearRect above).
          drawDot(context, dot)

          const newDot = {...dot}
          // Adjust the dot's x and y values down and to the right.
          newDot.x = (canvasWidth + pathArray[index].pathX / 10 * 2 * (currentFrame - 1)) / 2;
          newDot.y = (canvasHeight + pathArray[index].pathY / 10 * 2 * (currentFrame - 1)) / 2;
          // newDot.radius = dot.radius * 0.975;
          // newDot.alpha = dot.alpha - opacityDiffPerFrame
          tempDotsArray.push(newDot);
        })
        dotsArray = [...tempDotsArray]

        // Move the current time forward by one frame.
        currentFrame += 1;
        // If we've reached our maximum number of frames,
        // End the animation
        if (currentFrame === totalFrameCount) {
            intervalTimer.current = window.setTimeout(()=>{
              currentFrame = 0;
              // dotsArray = [...initialDotArray];
              pathArray = [...initialPathArray];
            }, item.bubbleInterval * 1000);
        }
        // Call this function again.
        requestRef.current = requestAnimationFrame(moveDot);
      }

      requestRef.current = requestAnimationFrame(moveDot)
    }
    return () => {
      window.clearTimeout(intervalTimer.current);
      cancelAnimationFrame(requestRef.current);
    }
  }, [context, item, containerRef, dotRadius])




  const drawDot = (context, dot) => {
    context.current.beginPath();

    context.current.strokeStyle = dot.color;
    context.current.lineWidth = 1;
    context.current.arc(dot.x, dot.y, dot.radius, 0, Math.PI * 1.5, true);
    context.current.stroke();

    context.current.globalAlpha = dot.alpha;
    context.current.arc(dot.x, dot.y, dot.radius, 0, 2 * Math.PI, false);
    context.current.fillStyle = dot.color;
    context.current.stroke();
  }

  const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function hexToRgbA(hex, opacity){
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length=== 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      // @ts-ignore
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', ' + opacity +')';
    }
    throw new Error('Bad Hex');
  }

  return (
    <canvas
      ref={canvas}
      className="hero-canvas absolute w-100 h-100 top-0 left-0 z-50"
      width={containerRef.current?.offsetWidth}
      height={containerRef.current?.offsetHeight}
    />
  )
}
export default FloatingItems;