import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Line from "../components/Line";
import allProjects from '../portfolio-data.json'
import ProjectCard from "../components/ProjectCard";

const Portfolio = () => {
  const [currentTab, setCurrentTab] = useState('all');
  const [displayedProjects, setDisplayedProjects] = useState([...allProjects]);

  const selectTab = (tab) => {
    if(tab==='all'){
      setDisplayedProjects([...allProjects]);
    }
    else {
      setDisplayedProjects(allProjects.filter(a=>a.category.includes(tab)));
    }
    setCurrentTab(tab)
  }

  return (
    <div>
      <Navbar />
      <div className="portfolio-page">

        <div className="portfolio-page-head">
          <Line width="140px" /> Portfolio
        </div>
        <div className="category">
          <button className={`category-btn ${currentTab === 'all' ? 'active': ''}`} onClick={()=>selectTab('all')}>All</button>
          <button className={`category-btn ${currentTab === 'web' ? 'active': ''}`}  onClick={()=>selectTab('web')}>Web</button>
          <button className={`category-btn ${currentTab === 'mobile' ? 'active': ''}`}  onClick={()=>selectTab('mobile')}>Mobile</button>
          <button className={`category-btn ${currentTab === 'blog' ? 'active': ''}`}  onClick={()=>selectTab('blog')}>Blogs</button>
        </div>

        <div className="portfolio-items">
          {
            displayedProjects && displayedProjects.map((project, index)=>(
              <ProjectCard project={project}  key={index} />
            ))
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Portfolio;