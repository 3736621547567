import Navbar from "../components/Navbar";
import React, {useEffect, useState} from "react";
import Footer from "../components/Footer";
import Line from "../components/Line";
import {Link, useParams} from "react-router-dom";
import AllPortfolio from '../portfolio-data.json';

const PortfolioItem = ()=>{
  const { id } = useParams();
  const [currentProject, setCurrentProject] = useState({});

  useEffect(()=>{
    setCurrentProject(AllPortfolio.find(a=>a.id===parseInt(id)));
  }, [id]);

  return (
    <div>
      <Navbar />
      <div className="portfolio-page">
        <div className="portfolio-page-head">
          <Line width="140px" /> {currentProject?.name}
        </div>
        <div className="pp">
          <div className="pp-image">
            <a href={currentProject?.url}  target="_blank" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/projects/${currentProject?.gifUrl}`} alt=""/>
            </a>
          </div>
          <div className="pp-description">
            <div className="pp-description-head">
              Description
            </div>
            <div dangerouslySetInnerHTML={{__html: currentProject?.description}} className="pp-description-text">
            </div>
          </div>
          <div className="pp-description">
            <div className="pp-description-head">
              Live URL
            </div>
            <div  className="pp-description-text">
              <a href={currentProject?.url} target="_blank" rel="noreferrer">
                {currentProject?.url}
              </a>
            </div>
          </div>
          <div className="pp-description">
            <div className="pp-description-head">
              Technologies Used
            </div>
            <div className="pp-description-tech">
              {currentProject?.technologiesUsed?.map((tech, index)=>(
                <span key={index}>
                  {tech}{`${index!==(currentProject?.technologiesUsed.length-1)? ', ':''}`}
                </span>
              ))}
            </div>
          </div>
          <div className="pp-back">
            <Link to={-1} className="pp-back-text">
              <Line  /> Back
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default PortfolioItem;