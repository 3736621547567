const Line = (props) => {
  const { width, color, height } = props;
  return (
    <div
      className="line"
      style={{
        display: "inline-block",
        width: width ? width : "20px",
        backgroundColor: color ? color : "#FFFFFF",
        height: height ? height : "2px",
      }}
    >

    </div>
  )
}
export default Line;