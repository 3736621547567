import React from "react";
import Line from "./Line";
import {Link} from "react-router-dom";

const ProjectCard = (props)=>{
  const { project } = props;
  return (
    <Link to={`/portfolio/${project.id}`}>
      <div className="card">
        <div className="card-image">
          <img src={`${process.env.PUBLIC_URL}/projects/${project.imageUrl}`} alt="" className="card-image-img" />
        </div>
        <div className="card-info">
          <div className="card-name">
            {project.name}
          </div>
          <div className="card-button">
            <Link to={`/portfolio/${project.id}`} className="btn-text card-button-btn" >
              <Line />
              View more
            </Link>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default ProjectCard;