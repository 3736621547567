import React, {useEffect, useRef, useState} from "react";
import isEmail from 'validator/es/lib/isEmail';
import Line from "../components/Line";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Contact = () =>{
  const [isLoading, setIsLoading] = useState(false);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  useEffect(()=>{

    function handleFormSubmit(event) {
      // handles form submit without any jquery
      event.preventDefault(); // we are submitting via xhr below
      var form = event.target;
      var formData = getFormData(form);
      var data = formData.data;

      // If a honeypot field is filled, assume it was done so by a spam bot.
      if (formData.honeypot) {
        return false;
      }

      disableAllButtons(form);
      var url = form.action;
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      // xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          form.reset();
          var formElements = form.querySelector('.form-elements');
          if (formElements) {
            formElements.style.display = 'none'; // hide form
          }
          var thankYouMessage = form.querySelector('.thankyou_message');
          if (thankYouMessage) {
            thankYouMessage.style.display = 'block';
          }
        }
      };
      // url encode form data for sending as post data
      var encoded = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
        })
        .join('&');
      xhr.send(encoded);
    }

    var forms = document.querySelectorAll('form.gform');
    for (var i = 0; i < forms.length; i++) {
      forms[i].addEventListener('submit', handleFormSubmit, false);
    }
  }, [])

  const validateName = () => {
    const nameFieldContent = nameRef.current.value;
    if (nameFieldContent === '') {
      document.getElementById('nameValidator').innerHTML = 'Name is required';
      return false;
    } else {
      document.getElementById('nameValidator').innerHTML = '';
      return true;
    }
  }

  const validateEmail = () => {
    const emailFieldContent = emailRef.current.value;
    if (emailFieldContent === '') {
      document.getElementById('emailValidator').innerHTML = 'Email is required';
      return false;
    } else if (!isEmail(emailFieldContent)) {
      document.getElementById('emailValidator').innerHTML =
        'Please enter a valid email';
      return false;
    } else {
      document.getElementById('emailValidator').innerHTML = '';
      return true;
    }
  }

  const validateMessage = () => {
    const messageFieldContent = messageRef.current.value;
    if (messageFieldContent === '') {
      document.getElementById('messageValidator').innerHTML =
        'Message is required';
      return false;
    } else {
      document.getElementById('messageValidator').innerHTML = '';
      return true;
    }
  }

    const validateAll = () => {
    if (validateName() && validateEmail() && validateMessage()) {
      document.getElementById('submit-button').click();
      setIsLoading(true);
    }
  }

  function getFormData(form) {
    var elements = form.elements;
    var honeypot;

    var fields = Object.keys(elements)
      .filter(function (k) {
        if (elements[k].name === 'honeypot') {
          honeypot = elements[k].value;
          return false;
        }
        return true;
      })
      .map(function (k) {
        if (elements[k].name !== undefined) {
          return elements[k].name;
          // special case for Edge's html collection
        } else if (elements[k].length > 0) {
          return elements[k].item(0).name;
        }else {
          return "";
        }
      })
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos && item;
      });

    var formData = {};
    fields.forEach(function (name) {
      var element = elements[name];

      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        var data = [];
        for (var i = 0; i < element.length; i++) {
          var item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(', ');
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || 'responses'; // default sheet name
    formData.formGoogleSendEmail = form.dataset.email || ''; // no email by default

    return { data: formData, honeypot: honeypot };
  }

  function disableAllButtons(form) {
    var buttons = form.querySelectorAll('button');
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }

  return (
    <div>
      <Navbar />
      <div className="contact contact-page">
        <div className="contact-head">
          <Line width="140px" /> <h2>Get <strong>In Touch</strong></h2>
        </div>
        <div className="contact-main">
          <div className="contact-form col-md-7 mb-5 mb-md-0">
            <form action="https://script.google.com/macros/s/AKfycbw8sJ1NoGqeqRoMuN-3ubGiV5kMSoN0UYFJmD2A/exec"
                 className="gform site-form" id="contact-form" method="post">

              <div className="form-group">

                <input
                  id="name"
                  ref={nameRef}
                  type="text"
                  onBlur={validateName}
                  className="form-control px-3 py-4"
                  placeholder="* Your Name"
                  disabled={isLoading}
                />
                <label htmlFor="name">Your Name</label>
                <span className="error-message" id="nameValidator"></span>
              </div>
              <div className="form-group">
                <input
                  ref={emailRef}
                  id="email"
                  name="email"
                  type="email"
                  onBlur={validateEmail}
                  className="form-control px-3 py-4"
                  placeholder="* Your Email"
                  disabled={isLoading}
                />
                <label htmlFor="email">Your Email</label>
                <span className="error-message" id="emailValidator"></span>
              </div>
              <div className="form-group">
                <input
                  id="phoneNumber"
                  type="text"
                  name="phone"
                  className="form-control px-3 py-4"
                  placeholder="Your Phone"
                  disabled={isLoading}
                />
                <label htmlFor="phoneNumber">Your Phone</label>
              </div>
              <div className="form-group mb-5">
                  <textarea
                    ref={messageRef}
                    name="message"
                    className="form-control px-3 py-4"
                    cols="30"
                    rows="10"
                    onBlur={validateMessage}
                    placeholder="* Write a Message"
                    disabled={isLoading}
                  ></textarea>
                <span className="error-message" id="messageValidator"></span>
              </div>
              <div className="form-group">
                <span id="errorMessage" style={{color: "red"}}></span>
                <button
                  type="button"
                  className="btn btn-primary px-4 py-3"
                  onClick={validateAll}
                  disabled={isLoading}
                >
                  Send Message
                </button>
                <input type="submit" name="" id="submit-button" style={{ display: "none"}} />
              </div>
              <div style={{ display: "none" }} className="thankyou_message">
                <div className="thankyou_message_text">
                  {/*You can customize the thankyou message by editing the code below*/}
                  <h2><em>Thanks</em> for contacting me! I will get back to you soon!
                  </h2>
                </div>
              </div>
            </form>
          </div>
          <div className="contact-info col-md-5 pl-md-5">
            <h3 className="contact-info-head">My Contact Details</h3>
            <ul className="site-contact-details">
              <li>
                <span className="text-uppercase">Email</span>
                <a href="mailto:yinkash1000@gmail.com">yinkash1000@gmail.com</a>
              </li>
              <li>
                <span className="text-uppercase">Phone</span>
                <a href="https://wa.link/mh49r4" target="_blank" rel="noreferrer noopener">+234 907 022 3427</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact;