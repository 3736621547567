import {Link} from "react-router-dom";

const Navbar = ()=>{
  return (
    <div className="nav">
      <ul className="nav-links">
        <li className="nav-list-item">
          <Link to="/">
            Home
          </Link>
        </li>
        {/*
        <li className="nav-list-item">
          <Link to={`${process.env.PUBLIC_URL}#about`}>
            About
          </Link>
        </li>
        */}
        <li className="nav-list-item">
          <Link to={'/portfolio'}>
            Portfolio
          </Link>
        </li>
      </ul>
      <div className="nav-links">
        <Link to="/contact-me">
          Contact Me
        </Link>
      </div>
  </div>
  )
}

export default Navbar;